import React from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { LeadershipData } from '../../../data/about-db';

export default function LeadershipTeam() {
  return (
    <section className="leadership-team pt-5">
      <Container>
        <Row>
          <h2 className="text-center leadership-team-title">
            {LeadershipData[0].heading}
            {' '}
            <span className="infenox-text-highlight team_height" />
          </h2>

          {LeadershipData[0].data.map(({
            id, name, position, desc, image, linkedin, twitter,
          }) => (
            <Col className="leadership-team-card team-box" key={id}>
              <Card className="flex-card h-100">
                <img src={image} alt="Profile" className="leadership-team-profile" />
                <Card.Body className="leadership-team-body">
                  <Card.Title className="text-center">{name}</Card.Title>
                  <span className="infenox-text-highlight team_height" />
                  <Card.Text className="leader_text custom-text-justify">{position}</Card.Text>
                  <Card.Text className="custom-text-justify">{desc}</Card.Text>
                </Card.Body>
                <div className="leadership-team-card-footer text-start">
                  <a className="face-icon-about brand-color-linkedin mx-1" href={linkedin}>{LeadershipData[0].icon2}</a>
                  <a className="face-icon-about brand-color-twitter mx-1 infenox-demo" href={twitter}>{LeadershipData[0].icon1}</a>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
